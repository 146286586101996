/* eslint-disable */
import React from "react"
import { graphql } from 'gatsby'
import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import RenderBlock from '../../components/blocks/RenderBlock'

import EventTracker from "../../components/common/EventTracker";
import Offers from "../../components/common/Offers";
import fixImageUrl from "../../../plugins/gatsby-source-episerver/fixImageUrl";
import ImageRightTextLeft from "../../components/blocks/ImageRightTextLeftBlock";


const AboutRoyale = ({ data }) => {
  let image3 = fixImageUrl("../../../assets/images/Web_ClusterPacks_Purr-oudlyCanadian.jpg");
  let image3Text = [
    {
      "__typename": "HeaderBlock",
      "type": "h2",
      "title": "A Proud Canadian Company",
      "guid": "image3header"
    },
    {
      "__typename": "TextBlock",
      "text": "We’re a Canadian company that makes quality products, trusted to help make everyday tasks a little easier for everyone who uses them. We love being part of a team that is passionate and diverse. We compete (and win) on a global level every day.",
      "guid": "image3text"
    }
  ];

  // Remove some content blocks
  // I do not want to remove them from Episerver because the client will ask us to put them all back
  // Just remove this when we need to put the content back
  const blocks = data.defaultPage.blocks;
  blocks.shift();
  blocks.shift();
  blocks.shift();
  blocks.shift();
  blocks.shift();

  const urls = data.defaultPage.urls;
  urls.en = '/purr-oudlycanadian'
  urls.fr = '/fr/fierrrementcanadienne'

  return (
    <Layout
      meta={data.defaultPage.meta}
      urls={urls}
      locale='en'
    >
      <Header
        urls={urls}
        locale='en'
      />
      <main>
        <ImageRightTextLeft alt="A PURR-oudly Canadian Company" image={image3} left={image3Text} caption="Faison – Operator, Toronto Plant" modification="center morepadding" />
        <RenderBlock blocks={blocks} />
      </main>

      <EventTracker gtag={{'allow_custom_scripts': true,'send_to': 'DC-8521957/invmedia/tiger0+standard'}} />
      <img height="1" width="1" style={{"border-style":"none"}} alt="Royale" src="https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:14yinok&fmt=3" />
      <Offers />
      <Footer showExtraLegal />
    </Layout>
  )
}

export const query = graphql`
query {
  defaultPage(guid: { eq:"5c1c9570-0086-4a6f-ac11-7460dc6481ac"}, locale: {eq:"en"}) {
    title
    title_html
    subtitle
    description
    image_url
    blocks {
      __typename
      ...textBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...videoBlockFragment
      ...imageBlockFragment
      ...threeColumnWithIconsBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default AboutRoyale
